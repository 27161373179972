import React from 'react'
import PropTypes from 'prop-types'

const Offerings = ({ gridItems }) => (
  <div className="row">
    {gridItems.map(item => (

      <div key={item.image} className="col-md-4">
        <section className='section1' >
          <div className='imgtask'>
            <img alt='' src={item.image}  />
            <span>{item.title}</span>
          </div>
          <p>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Offerings
