import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import * as PropTypes from 'prop-types'

const HomePageTemplate = ({
                            title,
                            heading,
                            description,
                            offerings,
                            meta_title,
                            meta_description,
                            testimonials,
                          }) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description}/>
    </Helmet>

    {/*<section className='section section--gradient'>*/}
    {/*<div className='hero-body'>*/}
    {/*    <div id="video-bg">
      <video  autoPlay loop >
        <source src="video/video.mp4" type="video/mp4" />
        <source src="video/video.mp4" type="video/ogg" />
      </video>
    </div>
 */}
    <div className='imgblock'>
      <img className='poster' alt='' src='/img/crantechnik.jpg'/>

    </div>

    <div className='container'>
      <div className='section1'>
        <div className='col-md-10 col-sm-8 col-xs-10 offset-sm-1'>
      <h1 className='title'>Переваги нашої продукції</h1>
          <div className="row">
            <div className="col-md-3">
              <div className='imgplus'>
                <img alt='' src='/img/de.png'/>
                <p className='plus'>НІМЕЦЬКА ЯКІСТЬ ПРОДУКТУ</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className='imgplus'>
                <img alt='' src='/img/ce.png'/>
                <p className='plus'>ТІЛЬКИ ЄВРОПЕЙСЬКЕ ВИРОБНИЦТВО</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className='imgplus'>
                <img alt='' src='/img/wide.png'/>
                <p className='plus'>ШИРОКИЙ СПЕКТР І РІЗНОМАНІТНІ ВАРІАНТИ</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className='imgplus'>
                <img alt='' src='/img/optim.png'/>
                <p className='plus'>ОПТИМАЛЬНЕ СПІВВІДНОШЕННЯ «ЦІНА-ЯКІСТЬ»</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

{/*  <div className='container'>
      <div className='section1'>
        <div className='columns'>

          <div className='column is-10 is-offset-1'>
            <div className='content'>
              <div>
                <h3 className='has-text-weight-semibold is-size-2'>
                  {heading}
                </h3>
                <p className='text-description'>{description}</p>
              </div>


              <Offerings gridItems={offerings.blurbs}/>
              <h2 className='has-text-weight-semibold is-size-2'>Testimonials</h2>
              <Testimonials testimonials={testimonials} />
            </div>
          </div>
        </div>
      </div>
    </div>*/}

    <div className='container'>
      <div className='section1'>
        <div className='col-md-8 col-sm-6 col-xs- offset-sm-2'>
          <h1 className='title'>{heading}</h1>
          <p className='text-description'>{description}</p>
          {/*<div className="row">*/}
            {/*<Offerings gridItems={offerings.blurbs}/>*/}

          {/*</div>*/}
        </div>
        <div className='col-md-10 col-sm-8 col-xs-10 offset-sm-1'>
          <Offerings gridItems={offerings.blurbs}/>
        </div>
      </div>
    </div>



    {/*</section>*/}
  </div>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  // testimonials: PropTypes.array,

}

export default HomePageTemplate
